import React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import Header from '../components/Header';
import Image from '../components/Image';
import Involve from '../../static/logos/donate.svg';

const Container = styled.div`
	text-align: center;
	padding: 5vh 5vw 0px 5vw;
	margin: 0 auto 10vh auto;

	@media only screen and (min-width: 768px) {
		padding: 5vh 20vw 0px 20vw;
	}
`;

const TinyText = styled.p`
	font-size: 0.7em;
`;

const BlankSpace = styled.div`
	height: 5vh;
`;

const FormLink = styled.div`
	width: 150px;
	color: #38c938;
	border-radius: 10px;
	border: solid #38c938 0.5px;
	padding: 10px;
	font-size: 1.2em;
	background-color: transparent;
	transition: 0.5s ease-in-out;
	outline: none;
	margin: 20px auto;
	display: block;
	cursor: pointer;

	:focus{
		outline: 0;
	}

	:hover{
        color: #fff;
        background-color: #38c938;
    }
`;

const GetInvolved = () => {
	return (
		<Container>
			<Header h3>Join the effort of improving health worldwide.</Header>
			<p>
				Interested in a partnership, helping with fundraising, creating
				educational materials, international field work, or our technology?
			</p>
			<FormLink
				href="https://glucosetrail.typeform.com/to/QKDSwV"
				target="blank"
			>
				Let's Talk!
			</FormLink>
			<Image square src={Involve} />
			<Header h3>Your Dollar Goes a Long Way</Header>
			<p>
				100% of donations goes to direct patient care and local human resource
				development
			</p>
			<p>Give a lasting gift of self sustainability, dignity and wellness.</p>
			<FormLink onClick={() => navigate('/waystodonate')}>Donate</FormLink>
			{/* <form
				action="https://www.paypal.com/cgi-bin/webscr"
				method="post"
				target="_top"
			>
				<input type="hidden" name="cmd" value="_s-xclick" />
				<input type="hidden" name="hosted_button_id" value="AZLNYMUUH6MB2" />
				<input
					type="image"
					src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
					border="0"
					name="submit"
					title="PayPal - The safer, easier way to pay online!"
					alt="Donate with PayPal button"
				/>
				<img
					alt=""
					border="0"
					src="https://www.paypal.com/en_US/i/scr/pixel.gif"
					width="1"
					height="1"
				/>
			</form> */}
			<TinyText>
				Glucose Trail Inc is a Public Non-Profit under IRS Section 501(c)(3).
			</TinyText>
			<BlankSpace />
			
		</Container>
	);
};

export default GetInvolved;
