import React from 'react';
import styled from '@emotion/styled';

import Image from '../components/Image';
import Icon from '../components/Icon';
import Header from '../components/Header';

import conceptIMG from '../../static/logos/conceptIMG.svg';
import patient from '../../static/logos/patient.svg';
import healthworker from '../../static/logos/healthcareworker.svg';
import doctor from '../../static/logos/doctor.svg';
import veggie from '../../static/logos/veggie.svg';
import walking from '../../static/logos/walk.svg';
import medicine from '../../static/logos/medicine.svg';

const Container = styled.div`
    text-align: center;
    padding: 5vh 5vw 0px 5vw;
    margin: 0 auto;

    @media only screen and (min-width:768px){
        padding: 5vh 20vw 0px 20vw;
    }
`

const UnderPara = styled.p`
    margin-top: 10vh;
    margin-bottom: 5vh;
`

const RoleRow = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
`

const Role = styled.div`
    width: 90vw;
    
    @media only screen and (min-width:768px){
        max-width: 20vw;    
    }

    margin-bottom: 20px;
    text-align: center;
    padding-left: 7px;
    padding-right: 7px;
`

const Concept = (props) => {
    return(
        <Container>
            <Header h2>Solution: A Circle of Care</Header>
            <Image medSquare src={conceptIMG} />
            <UnderPara>Glucose Trail is a diabetes solution bringing education and care to the patient at home, harnessing the use of mobile health technology.</UnderPara>
            <RoleRow>
                <Role>
                    <Icon src={patient} />
                    <Header label>Patient</Header>
                    <p>Diabetes, a silent disease in its early stages, will be diagnosed and treated early, before irreversible complications set in.</p>
                </Role>
                <Role>
                    <Icon src={healthworker} />
                    <Header label>Health Care Companion</Header>
                    <p>Community healthcare companions will serve as the important link between remote doctors and patients. They create a circle of care utilizing community resources.</p>
                </Role>
                <Role>
                    <Icon src={doctor} />
                    <Header label>Doctor</Header>
                    <p>Diabetes specialists will drive affordable, sustainable and effective treatment plans remotely.</p>
                </Role>
                <Role>
                    <Icon src={veggie} />
                    <Header label>Clean Eating</Header>
                    <p>Local community grocers will provide patients with sustainable, healthy options for meal planning.</p>
                </Role>
                <Role>
                    <Icon src={walking} />
                    <Header label>Physical Activity</Header>
                    <p>Community recreational areas and home solutions will be created for daily physical activity.</p>
                </Role>
                <Role>
                    <Icon src={medicine} />
                    <Header label>Medication</Header>
                    <p>Local pharmacies will be identified to provide ongoing medication and insulin supplies.</p>
                </Role>
            </RoleRow>
        </Container>
    )
}

/**
 * TODO: Add row of "learn more" resources.
 * 1. Slideshow (presentation.svg) https://www.slideshare.net/SaalikLokhandwala/glucose-trail-a-community-based-diabetes-healthcare-solution
 * 2. Video (movie.svg) https://youtu.be/YH-yQknqzuo
 * 3. Concept Note (paper.svg) https://medium.com/glucose-trail/what-is-glucose-trail-2037c007de9e
 * 4. Cost Calculator (calculator.svg) https://calculator.glucosetrail.com/
 */

export default Concept;