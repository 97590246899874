import React from 'react';
import styled from '@emotion/styled';
//import {NavItem} from 'reactstrap';

/**
 * # NavDesktopItem Component
 */

const NavItem = styled.div`
    text-align: right;
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #444444;
    :hover{
        color: #a4a4a4;
    }
`

/**
 * ## Composition:
 * A desktop item that should be used as a child of the NavDesktop Component
 */

const NavDesktopItem = (props) => {
    return(
        <NavItem>
            {props.children}
        </NavItem>
    )
}

export default NavDesktopItem

