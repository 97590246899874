import React from 'react';
import styled from '@emotion/styled';

import Image from '../components/Image';
import Header from '../components/Header';
import { Popover, PopoverHeader, PopoverBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import teamData from './teamData';


const Container = styled.div`
	text-align: center;
	padding: 5vh 5vw 0px 5vw;
	margin: 0 auto;
	margin-top: 15vh;

	@media only screen and (min-width: 768px) {
		padding: 5vh 20vw 0px 20vw;
	}
`;

const TeamBox = styled.div`
	display: flex;
	justify-content: space-evenly;
	flex-flow: row wrap;
`;

const Member = styled.div`
	width: 90vw;
	cursor: pointer;

	@media only screen and (min-width: 768px) {
		max-width: 20vw;
	}

	margin-bottom: 20px;
	text-align: center;
`;

class Team extends React.Component {
	state = {
		modalOpen: false,
		infocontent: '',
		infotitle: '',
		infoname: '',
		infoimage: ''
	};

	// Toggle the modal on or off. After changing the state, attempt to update the current modal data
	toggleModal = (content, title, name, image) => {
		this.setState({
			modalOpen: !this.state.modalOpen,
		}, () => {
			this.updateCurrentModalData(content, title, name, image);
		})
	}

	// Modal data only updates when the modal is open and proper data is being passed in. 
	updateCurrentModalData = (content, title, name, image) => {
		if(this.state.modalOpen){
			this.setState({
				infocontent: content,
				infotitle: title,
				infoname: name,
				infoimage: image
			})
		}
	}

	render() {
		return (
			<Container>
				<Header h2>Team Members</Header>
				<Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
					<ModalHeader toggle={this.toggleModal}>{this.state.infoname}</ModalHeader>
					<ModalBody>
						<Image src={this.state.infoimage} circle/>
						<h3>{this.state.infotitle}</h3>
						<p>{this.state.infocontent}</p>
					</ModalBody>
				</Modal>
				<TeamBox>
					{teamData.map((data, index) => (
						<Member key={index} onClick={() => this.toggleModal(data.infocontent, data.infotitle, data.name, data.image)}>
							<Image circle src={data.image}/>
							<Header h5>{data.name}</Header>
							<Header label>{data.label}</Header>
						</Member>
					))}

					{/* <Member id="naaznin" onClick={this.toggleModal}>
						<Image circle src={naaznin} />
						<Header h5>Naaznin Lokhandwala MD</Header>
						<Header label>Co-Founder, Endocrinologist</Header>
					</Member>
					<Popover
						placement="top"
						isOpen={this.state.naazninOpen}
						target="naaznin"
						toggle={this.toggleNaaznin}
					>
						<PopoverHeader>Co-Founder, Endocrinologist</PopoverHeader>
						<PopoverBody>
							Consultant Endocrinologist, Steward Healthcare, graduated Aga Khan
							University Medical College, Karachi, Pakistan, Postdoctoral
							Training at Beth Israel Deaconess Hospital Harvard Boston MA and
							Lahey Clinic, Burlington, MA. American Board Certified in
							Endocrinology, Diabetes and Metabolism and Internal Medicine.
							<br /> <br /> Naaznin Lokhandwala MD grew up in Oregon and has
							spent considerable time in Pakistan. Having served several
							communities in the US and South Asia - private practice, academic centers,
							under-served, and a state psychiatric hospital, each population
							has given her a unique perspective informing this solution.
						</PopoverBody>
					</Popover>
					<Member id="shafiq" onClick={this.toggleShafiq}>
						<Image circle src={shafiq} />
						<Header h5>Shafiq Lokhandwala</Header>
						<Header label>Co-Founder, Software Engineer</Header>
					</Member>
					<Popover
						placement="bottom"
						isOpen={this.state.shafiqOpen}
						target="shafiq"
						toggle={this.toggleShafiq}
					>
						<PopoverHeader>
							Co-Founder, Executive, Financial, Fundraising and Technical Lead
						</PopoverHeader>
						<PopoverBody>
							Shafiq has led and grown companies in the software arena for the
							last 30 years. He has built four businesses, two each in India and
							the US. In doing so he has emerged as a leader who has built
							effective teams from scratch and grown them to profitable entities
							deploying a software platform for clients in the SMB space. His
							last company NuView Systems, Inc. in the US was a HRMS (Human
							Resource Management System) platform deployed to 400+ clients
							supporting over 400,000 employees that lived in over 85 countries
							in 21 different languages. Shafiq has demonstrated a strong sense
							for communicating with clients around their business outcomes and
							developing products and services to meet those outcomes. He builds
							teams rapidly that can create value with on time quality
							deliverables.
						</PopoverBody>
					</Popover>
					<Member id="usama" onClick={this.toggleUsama}>
						<Image circle src={usama} />
						<Header h5>Usama Javed</Header>
						<Header label>Advisor, Operations Lead</Header>
					</Member>
					<Popover
						placement="bottom"
						isOpen={this.state.usamaOpen}
						target="usama"
						toggle={this.toggleUsama}
					>
						<PopoverHeader>CEO, Saving9</PopoverHeader>
						<PopoverBody>
							Usama is CEO of Saving 9, an organization committed to creating
							communities of care in Pakistan around both physical and mental
							health education. He is also the founding curator of the STEAM Ed
							Initiative, nation-wide movement of education activists advocating
							for and building capacity towards better science and math
							education. He is a Global Shaper (an initiative of the World
							Economic Forum), and is an editor of the educational magazine, The
							Reformer. A Fulbright Scholar with a Masters from Teachers College
							Columbia University, where he graduated valedictorian. He is also
							a New York State certified Emergency Medical Technician, and has a
							bachelors in Physics from LUMS.
						</PopoverBody>
					</Popover>
					<Member id="faizan" onClick={this.toggleFaizan}>
						<Image circle src={faizan} />
						<Header h5>Faizan Timizi</Header>
						<Header label>Team Operations, Saving9</Header>
					</Member>
					<Popover
						placement="top"
						isOpen={this.state.faizanOpen}
						target="faizan"
						toggle={this.toggleFaizan}
					>
						<PopoverHeader>
							Head of Development Operations, Saving9
						</PopoverHeader>
						<PopoverBody>
							Faizan has Bachelors in Political Science from the Lahore
							University of Management Sciences in Pakistan, and has several
							years of experience in community engagement development work and
							teaching. He serves as Saving 9’s Head of Development Operations,
							and in this capacity has overseen the development of an ambulance
							system and first response network in rural Pind Begwal - a project
							funded by Harvard University. Faizan has also been Implementation
							Manager at Saving 9 for Glucose Trail’s currently ongoing
							year-long early stage diabetes telemedicine pilot project. Faizan
							is also certified Medical First Responder.
						</PopoverBody>
					</Popover>
					<Member id="saalik" onClick={this.toggleSaalik}>
						<Image circle src={saalik} />
						<Header h5>Saalik Lokhandwala</Header>
						<Header label>Product Manager</Header>
					</Member>
					<Popover
						placement="bottom"
						isOpen={this.state.saalikOpen}
						target="saalik"
						toggle={this.toggleSaalik}
					>
						<PopoverHeader>Product Manager, Glucose Trail</PopoverHeader>
						<PopoverBody>
							Saalik is a graduate from Bentley University with a major in
							Computer Information Systems and minors in psychology &
							information design. He has a keen interest in UI design and full
							stack mobile application development. Building vibrant communities
							and bridging meaningful connections with technology is the
							strength he envisions in his generation and he brings this
							enthusiasm and technical skill set to Glucose Trail.
						</PopoverBody>
					</Popover>
					{/* <Member id="daniyal" onClick={this.toggleDaniyal}>
						<Image circle src={daniyal} />
						<Header h5>Daniyal Kapadia</Header>
						<Header label>Team Operations, Mera Maan</Header>
					</Member>
					<Popover
						placement="bottom"
						isOpen={this.state.daniyalOpen}
						target="saalik"
						toggle={this.toggleDaniyal}
					>
						<PopoverHeader>Lead Operations, Mera Maan</PopoverHeader>
						<PopoverBody />
					</Popover> */}
					{/* <Member id="shahnaz" onClick={this.toggleShahnaz}>
						<Image circle src={shahnaz} />
						<Header h5>Shahnaz Kapadia</Header>
						<Header label>Advisor, CEO at Mera Maan (PVT) Limited</Header>
					</Member>
					<Popover
						placement="top"
						isOpen={this.state.shahnazOpen}
						target="shahnaz"
						toggle={this.toggleShahnaz}
					>
						<PopoverHeader>Founder, CEO, Mera Maan</PopoverHeader>
						<PopoverBody>
							Shahnaz has thirty years of national and international experience
							working as a master trainer, instructional designer and
							implementer of large-scale cascade projects and programs. As
							founder and Chief Executive Officer (CEO) Empowerment thru
							Creative Integration (ECI), Shahnaz specialized in large-scale
							cascade training for the development sector and the Government.
							During her 23 years leading ECI, she worked with the Federal and
							Provincial Governments, over 200 Civil Society Organizations, and
							a very large number of international NGOs and development agencies
							(World Bank, UNDP, UNICEF, JICA, CIDA, SIDA, UNHCR, UN Women,
							etc.). In 2013, Shahnaz sold ECI and joined the Pakistan Poverty
							Alleviation Fund as a Senior Group Head. In 2015, she proceeded to
							the Harvard Kennedy School, where as an Edward Mason Fellow, she
							complemented her previous Master's in Business Administration from
							the Institute of Business Administration, Karachi, with a Master's
							in Public Administration. Upon returning to Pakistan in June 2016,
							Shahnaz established Mera Maan (Pvt.) Ltd. as a Social Enterprise.
							As its Chief Executive Officer (CEO), she now continues her
							passionate interest in assisting policy makers and apex
							institutions to effectively design and implement large-scale
							'capacity for capacity' programs.
						</PopoverBody>
					</Popover>
					<Member id="didi" onClick={this.toggleDidi}>
						<Image circle src={didi} />
						<Header h5>Dinamarie Garcia-Banigan, MD, MPH</Header>
						<Header label>Board Member, Endocrinologist</Header>
					</Member>
					<Popover
						placement="top"
						isOpen={this.state.didiOpen}
						target="didi"
						toggle={this.toggleDidi}
					>
						<PopoverHeader>Board Member, Endocrinologist</PopoverHeader>
						<PopoverBody>
							Co-Director Center for Sexual and Reproductive Health, Division of
							Endocrinology Lahey Hospital and Medical Center Co-Director,
							International Team, International Health Program, Lahey Hospital
							and Medical Center Assistant Clinical Professor, Tufts University
							School of Medicine <br />
							<br />
							Her interest in diabetes began as a public health professional
							researching and educating about the preventable complications of
							diabetes. Now as a board certified internist and endocrinologist,
							I have been able to translate that information into direct patient
							care and management. Technology can bring together family support,
							organize patient self-management, and empower community members to
							take charge of their individual roles leading to improved health
							and well being for all.
						</PopoverBody>
					</Popover>
					<Member id="laura" onClick={this.toggleLaura}>
						<Image circle src={laura} />
						<Header h5>Laura Walta</Header>
						<Header label>Board Member</Header>
					</Member>
					<Popover
						placement="top"
						isOpen={this.state.lauraOpen}
						target="laura"
						toggle={this.toggleLaura}
					>
						<PopoverHeader>Board Member</PopoverHeader>
						<PopoverBody>
							Ms. Walta graduated with a degree in Economics and International
							Relations from Tufts University in 1981, and worked with a number
							of non-profit organizations as a program developer, trainer and
							grant writer until earning her MBA from Southern NH University in
							1991. In 1992 she formed RDL, Inc., a successful consulting firm
							focused on operational excellence and process/quality improvement
							tools and techniques. She is a Certified Project Manager and Six
							Sigma Black Belt statistician. She semi-retired in 2012, and took
							the position of Director of Global Mission for the Episcopal
							Diocese of Massachusetts, spending significant amounts of time in
							Haiti, Latin America, Africa, and the Middle East, developing
							partnerships there with congregations in MA and working with local
							leaders to develop capacity for community development. She will
							retire in 2019, and looks forward to dedicating more time to
							meaningful volunteer work. Incidentally, Ms. Walta has had Type 1
							Diabetes for 57 years, and with the aid of an insulin pump and
							continuous glucose monitor, remains in excellent control and
							physical condition.
						</PopoverBody>
					</Popover>
					<Member id="durriya" onClick={this.toggleDurriya}>
						<Image circle src={durriya} />
						<Header h5>Durriya Doctor</Header>
						<Header label>Board Member</Header>
					</Member>
					<Popover
						placement="bottom"
						isOpen={this.state.durriyaOpen}
						target="durriya"
						toggle={this.toggleDurriya}
					>
						<PopoverHeader>Board Member</PopoverHeader>
						<PopoverBody>
							Durriya Doctor is a SW Engineering and Technology leader with a
							proven track record of bringing new products to the market all the
							way from ideation to launch in multiple domains including Telecom
							Service, Video Editing and Operating System Services. Durriya has
							held executive and senior engineering management positions at
							Mavenir, Tech Mahindra USA and Comverse. She has worked at Apollo
							Computers, Open Software Foundation Research Institute, Avid
							Technology and Engage
							<br />
							Durriya’s current interest is in leveraging best practices of
							technology to transform the healthcare experience. She is
							passionate about making a difference in healthcare ease of access
							and reachability to all. Durriya is a graduate of IIT-Bombay and
							University of Michigan, Ann Arbor
						</PopoverBody>
					</Popover> */}
				</TeamBox>
			</Container>
		);
	}
}

export default Team;
