import React from 'react';
import styled from '@emotion/styled';

import collabs from '../../static/logos/Collabs.png';
import Image from '../components/Image';
import Header from '../components/Header';

import saving9 from '../../static/logos/saving9.png';
import pehlikiran from '../../static/logos/pehliKiran.png';
import meramaan from '../../static/logos/meramaan.png';
import appne from '../../static/logos/apnnalogo.jpg';
import ahk from '../../static/logos/AhkLogo.png';

const Container = styled.div`
    margin-top: 5vh;
    padding-top: 10vh;
    margin-bottom: 10vh;

    @media only screen and (min-width:768px){
        padding: 5vh 20vw 0px 20vw;
    }
`

const CollabBox = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
`

const Collaber = styled.div`
    width: 90vw;
    
    @media only screen and (min-width:768px){
        max-width: 20vw;    
    }

    margin-bottom: 20px;
    text-align: center;
`

const Collaborators = (props) => {
    return(
        <Container>
            <CollabBox>
                <Collaber>
                    <Image small src={saving9} />
                </Collaber>
                {/* <Collaber>
                    <Image small src={pehlikiran} />
                </Collaber> */}
                <Collaber>
                    <Image small src={meramaan} />
                </Collaber>
                {/* <Collaber>
                    <Image small src={appne} />
                </Collaber> */}
            </CollabBox>
        </Container>
    )
}

export default Collaborators;