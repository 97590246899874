import React from 'react';
import styled from '@emotion/styled';
import {
	Link,
	DirectLink,
	Element,
	Events,
	animateScroll as scroll,
	scrollSpy,
	scroller,
} from 'react-scroll';
import FadeIn from 'react-fade-in';

import Header from '../components/Header';
import Image from '../components/Image';
import NavDesktopItem from '../components/NavDesktopItem';
import NavDesktop from '../components/NavDesktop';

import Logo from '../../static/logos/glucoseLogo.png';
import Back from '../../static/logos/back.jpg';

const Background = styled.div`
	/*Full image background */
	background: url(${Back}) no-repeat center;
	background-size: cover;
	min-height: 90vh;
	color: #ffffff;
	padding: 35vh 5vw;
	text-align: center;

	@media only screen and (min-width: 768px) {
		padding: 35vh 30vw;
	}
`;

const NavToggle = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	position: absolute;
	background-color: #bada55;
`;

const OutsideLink = styled.a`
	text-decoration: none;
	color: #444444;
	:hover {
		color: #a4a4a4;
	}
`;

const Content = styled.div`
	padding: 10px;
	background: rgba(50, 50, 50, 0.75);
	@media only screen and (min-width: 768px) {
		padding: 30px;
	}
`;

const TopDiv = styled.div``;

const Home = props => {
	return (
		<div>
			<NavDesktop>
				<NavDesktopItem>
					<Link
						activeClass="active"
						to="problem"
						spy={true}
						smooth={true}
						duration={500}
						offset={-100}
					>
						The Problem{' '}
					</Link>
				</NavDesktopItem>
				<NavDesktopItem>
					<Link
						activeClass="active"
						to="concept"
						spy={true}
						smooth={true}
						duration={500}
						offset={-100}
					>
						Solution{' '}
					</Link>
				</NavDesktopItem>
				<NavDesktopItem>
					<Link
						activeClass="active"
						to="team"
						spy={true}
						smooth={true}
						duration={500}
						offset={-100}
					>
						Team{' '}
					</Link>
				</NavDesktopItem>
				<NavDesktopItem>
					<Link
						activeClass="active"
						to="getinvolved"
						spy={true}
						smooth={true}
						duration={500}
						offset={-100}
					>
						Get Involved{' '}
					</Link>
				</NavDesktopItem>
				<NavDesktopItem>
					<OutsideLink href="https://medium.com/glucose-trail" target="_blank">
						Blog{' '}
					</OutsideLink>
				</NavDesktopItem>
			</NavDesktop>
			<FadeIn delay="500" transitionDuration="1000">
				<Background>
					<Content>
						<Header h1>
							<b>A Global mHealth Project</b>
						</Header>
						<Header h2>For Treating Diabetes</Header>
						<br />
						<Header h3>
							Reach patients early, empower them to treat their diabetes
							smartly, and improve the well being of our world community.
						</Header>
					</Content>
				</Background>
			</FadeIn>
		</div>
	);
};

export default Home;
