import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {css, keyframes} from '@emotion/core';

/**
 * # Image Component
 */

const base = css`
    margin: 0 auto;
    display: block;
`

const RegImg = styled.img`
    ${base};
    max-width: 100%;
    height: auto;
`

const SquareImg = styled.img`
    ${base};
    width: 150px;
    height: 150px;
    padding: 15px;
`

const CircleImg = styled.img`
    ${base};
    width: 130px;
    height: 130px;
    border-radius: 50%;
`

const FullWidth = styled.img`
    ${base};
    width: 100vw;
    height: auto;
`

const MidPage = styled.img`
    ${base};
    width: 60vw;
    height: auto;
`

const Bounce = keyframes`
    0%, 100% {
        transform: translate(0px, -5px);
    }

    50% {
        transform: translate(0px, 5px);
    }
`

const Bouncing = styled.img`
    ${base};
    transition: all 0.3s ease 1s;
    animation: ${Bounce} 2s ease-in-out infinite;
    animation-delay: 1s;
`
const Flash = keyframes`
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }
`

const Flashing = styled.img`
    ${base};
    padding-top: 80px;
    width: 130px;
    transition: all 0.1s ease 1s;
    animation: ${Flash} 3s ease-in-out infinite;
    animation-delay: 1s;
`

const MedSquare = styled.img`
    ${base};
    padding-top: 50px;
    width: 400px;
    max-width: 90vw;
    margin-right: 5px;
    margin-left: 5px;
    margin: 0 auto;
`

const Small = styled.img`
    ${base};
    height: 100px;
`

/**
 * ## Composition:
 * 
 * Checks image type and renders image of that size/shape. Images must be passed into the src prop.
 * 
 * Types:
 * 
 * - Square: 130px by 130px
 * - Circle: 130px by 130px
 * - FullWidth: takes up the full width of the screen and then adjusts the height to auto
 * - MidPage: takes up 60vw of the screen and adjusts the height to auto
 * - Regular (default): takes up 100% of the parent div and adjusts the height to auto
 * - Others created
 */

const Image = (props) => {
    if(props.square){
        return(<SquareImg src={props.src}/>)
    }
    else if(props.circle){
        return(<CircleImg src={props.src}/>)
    }
    else if(props.full){
        return(<FullWidth src={props.src}/>)        
    }
    else if(props.med){
        return(<MidPage src={props.src}/>)        
    }
    else if(props.flashing){
        return(<Flashing src={props.src}/>)
    }
    else if(props.medSquare){
        return(<MedSquare src={props.src}/>)
    }
    else if(props.bouncing){
        return(<Bouncing src={props.src}/>)
    }
    else if(props.small){
        return(<Small src={props.src}/>)
    }
    else{
        return(<RegImg src={props.src}/>)        
    }    
}

export default Image

/**
 * ## Props:
 * 
 * - square: boolean
 * - circle: boolean
 * - full: boolean
 * - med: boolean
 * - src: string
 */
Image.propTypes = {
    square: PropTypes.bool,
    circle: PropTypes.bool,
    full: PropTypes.bool,
    med: PropTypes.bool,
    flashing: PropTypes.bool,
    medSquare: PropTypes.bool,
    bouncing: PropTypes.bool,
    small: PropTypes.bool,
    src: PropTypes.string
}
