import React from 'react';
import {HorizontalBar} from 'react-chartjs-2';

const data = {
    labels: ['Early Stage', 'Insulin Stage', 'Late Stage', 'Late Stage + Complications'],
    datasets: [
        {
            label: 'Cost',
            backgroundColor: 'rgba(56,200,56, 0.7)',
            borderColor: 'rgba(255, 255, 255, 0.5)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(56,200,56, 1)',
            hoverBorderColor: 'rgba(255, 255, 255, 1)',
            data: [1, 5, 80, 175]
        }
    ]
}

const CostGraph = () => {
    return(
        <HorizontalBar data={data}/>
    )
}

export default CostGraph;