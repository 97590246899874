import naaznin from '../../static/logos/naaznin.jpg';
import shafiq from '../../static/logos/shafiq.jpeg';
import saalik from '../../static/logos/saalik.jpg';
import ayesha from '../../static/logos/ayeshak.jpeg';
import shahnaz from '../../static/logos/shahnaz.jpeg';
import didi from '../../static/logos/dinamarie.jpg';
import usama from '../../static/logos/usama.png';
import faizan from '../../static/logos/faizan.png';
import rashida from '../../static/logos/rashida.jpg';
//import daniyal from '../../static/logos/daniyal.jpeg';
import laura from '../../static/logos/laura.jpg';
import durriya from '../../static/logos/durriya.jpg';
import johntroy from '../../static/logos/johntroy.jpeg';
import malefiller from '../../static/logos/malefill.svg';
import femalefiller from '../../static/logos/femalefill.svg';

const teamData = [
	{
		name: 'Naaznin Lokhandwala',
		label: 'Co-Founder, Endocrinologist',
		image: naaznin,
		infotitle: 'Co-Founder, Endocrinologist',
		infocontent: 'Consultant Endocrinologist, Steward Healthcare, graduated Aga Khan University Medical College, Karachi, Pakistan, Postdoctoral Training at Beth Israel Deaconess Hospital Harvard Boston MA and Lahey Clinic, Burlington, MA. American Board Certified in Endocrinology, Diabetes and Metabolism and Internal Medicine. Naaznin Lokhandwala MD grew up in Oregon and has spent considerable time in Pakistan. Having served several communities in the US and South Asia - private practice, academic centers, under-served, and a state psychiatric hospital, each population has given her a unique perspective informing this solution.',
    },
    {
		name: 'Shafiq Lokhandwala',
		label: 'Co-Founder, Software Engineer',
		image: shafiq,
		infotitle: 'Co-Founder, Executive, Financial, Fundraising and Technical Lead',
		infocontent: 'Shafiq has led and grown companies in the software arena for the last 30 years. He has built four businesses, two each in India and the US. In doing so he has emerged as a leader who has built effective teams from scratch and grown them to profitable entities deploying a software platform for clients in the SMB space. His last company NuView Systems, Inc. in the US was a HRMS (Human Resource Management System) platform deployed to 400+ clients supporting over 400,000 employees that lived in over 85 countries in 21 different languages. Shafiq has demonstrated a strong sense for communicating with clients around their business outcomes and developing products and services to meet those outcomes. He builds teams rapidly that can create value with on time quality deliverables.',
    },
    {
		name: 'Usama Javed',
		label: 'Advisor, Operations Lead',
		image: usama,
		infotitle: 'CEO, Saving9',
		infocontent: 'Usama is CEO of Saving 9, an organization committed to creating communities of care in Pakistan around both physical and mental health education. He is also the founding curator of the STEAM Ed Initiative, nation-wide movement of education activists advocating for and building capacity towards better science and math education. He is a Global Shaper (an initiative of the World Economic Forum), and is an editor of the educational magazine, The Reformer. A Fulbright Scholar with a Masters from Teachers College Columbia University, where he graduated valedictorian. He is also a New York State certified Emergency Medical Technician, and has a bachelors in Physics from LUMS.',
    },
    {
		name: 'Faizan Tirmizi',
		label: 'Team Operations, Saving9',
		image: faizan,
		infotitle: 'Head of Development Operations, Saving9',
		infocontent: 'Faizan has Bachelors in Political Science from the Lahore University of Management Sciences in Pakistan, and has several years of experience in community engagement development work and teaching. He serves as Saving 9’s Head of Development Operations, and in this capacity has overseen the development of an ambulance system and first response network in rural Pind Begwal - a project funded by Harvard University. Faizan has also been Implementation Manager at Saving 9 for Glucose Trail’s currently ongoing year-long early stage diabetes telemedicine pilot project. Faizan is also certified Medical First Responder.',
    },
    {
		name: 'Saalik Lokhandwala',
		label: 'Product Manager',
		image: saalik,
		infotitle: 'Product Manager, Glucose Trail',
		infocontent: 'Saalik is a graduate from Bentley University with a major in Computer Information Systems and minors in psychology & information design. He has a keen interest in UI design and full stack mobile application development. Building vibrant communities and bridging meaningful connections with technology is the strength he envisions in his generation and he brings this enthusiasm and technical skill set to Glucose Trail.',
    },
    {
		name: 'Shahnaz Kapadia',
		label: 'Advisor, CEO at Mera Maan',
		image: shahnaz,
		infotitle: 'Founder, CEO at Mera Maan (PVT) Limited',
		infocontent: 'Shahnaz has thirty years of national and international experience working as a master trainer, instructional designer and implementer of large-scale cascade projects and programs. As founder and Chief Executive Officer (CEO) Empowerment thru Creative Integration (ECI), Shahnaz specialized in large-scale cascade training for the development sector and the Government. During her 23 years leading ECI, she worked with the Federal and Provincial Governments, over 200 Civil Society Organizations, and a very large number of international NGOs and development agencies (World Bank, UNDP, UNICEF, JICA, CIDA, SIDA, UNHCR, UN Women, etc.). In 2013, Shahnaz sold ECI and joined the Pakistan Poverty Alleviation Fund as a Senior Group Head. In 2015, she proceeded to the Harvard Kennedy School, where as an Edward Mason Fellow, she complemented her previous Masters in Business Administration from the Institute of Business Administration, Karachi, with a Masters in Public Administration. Upon returning to Pakistan in June 2016, Shahnaz established Mera Maan (Pvt.) Ltd. as a Social Enterprise. As its Chief Executive Officer (CEO), she now continues her passionate interest in assisting policy makers and apex institutions to effectively design and implement large-scale "capacity for capacity" programs.',
    },
    {
		name: 'Dinamarie Garcia-Banigan, MD, MPH',
		label: 'Board Member, Endocrinologist',
		image: didi,
		infotitle: 'Board Member, Endocrinologist',
		infocontent: 'Co-Director Center for Sexual and Reproductive Health, Division of Endocrinology Lahey Hospital and Medical Center Co-Director, International Team, International Health Program, Lahey Hospital and Medical Center Assistant Clinical Professor, Tufts University School of Medicine. Her interest in diabetes began as a public health professional researching and educating about the preventable complications of diabetes. Now as a board certified internist and endocrinologist, I have been able to translate that information into direct patient care and management. Technology can bring together family support, organize patient self-management, and empower community members to take charge of their individual roles leading to improved health and well being for all.',
    },
    {
		name: 'Laura Walta',
		label: 'Board Member',
		image: laura,
		infotitle: 'Board Member',
		infocontent: 'Ms. Walta graduated with a degree in Economics and International Relations from Tufts University in 1981, and worked with a number of non-profit organizations as a program developer, trainer and grant writer until earning her MBA from Southern NH University in 1991. In 1992 she formed RDL, Inc., a successful consulting firm focused on operational excellence and process/quality improvement tools and techniques. She is a Certified Project Manager and Six Sigma Black Belt statistician. She semi-retired in 2012, and took the position of Director of Global Mission for the Episcopal Diocese of Massachusetts, spending significant amounts of time in Haiti, Latin America, Africa, and the Middle East, developing partnerships there with congregations in MA and working with local leaders to develop capacity for community development. She will retire in 2019, and looks forward to dedicating more time to meaningful volunteer work. Incidentally, Ms. Walta has had Type 1 Diabetes for 57 years, and with the aid of an insulin pump and continuous glucose monitor, remains in excellent control and physical condition.',
    },
    {
		name: 'Rashida Abbas Ferrand',
		label: 'Advisor, Epidemiologist',
		image: rashida,
		infotitle: 'Advisor, Epidemiology and Public Health',
		infocontent: 'Rashida is a professor at the London School of Hygiene and Tropical Medicine (LSHTM) and a Wellcome Trust Senior Fellow in Clinical Science. She  obtained her medical degree from the University of Newcastle-upon-Tyne and following postgraduate training in internal medicine and clinical specialist training in HIV Medicine and Sexual Health, she trained as an epidemiologist. Rashida’s key focus is to strengthen public sector health systems and to develop research capacity and her research group has grown from 3 staff in 2007 to over a 100 staff that includes 11 PhD students and several postdoctoral students. Her group has generated evidence that has informed national and international policy including HIV testing and treatment guidelines in adolescents, a focus on chronic comorbidities and more recently development of WHO guidelines for school health services.',
    },
    {
		name: 'Durriya Doctor',
		label: 'Board Member',
		image: durriya,
		infotitle: 'Board Member',
		infocontent: 'Durriya Doctor is a SW Engineering and Technology leader with a proven track record of bringing new products to the market all the way from ideation to launch in multiple domains including Telecom Service, Video Editing and Operating System Services. Durriya has held executive and senior engineering management positions at Mavenir, Tech Mahindra USA and Comverse. She has worked at Apollo Computers, Open Software Foundation Research Institute, Avid Technology and Engage Durriya’s current interest is in leveraging best practices of technology to transform the healthcare experience. She is passionate about making a difference in healthcare ease of access and reachability to all. Durriya is a graduate of IIT-Bombay and University of Michigan, Ann Arbor',
	},
	{
		name: 'John Troy',
		label: 'Former Board Member',
		image: johntroy,
		infotitle: 'Former Board Member',
		infocontent: 'John D. Troy, 69 of Atkinson, NH, died peacefully on June 23, 2019 at the High Pointe Hospice House, Haverhill, MA after a courageous battle with Pancreatic Cancer. John owned and operated Connectivity Inc. in Atkinson, NH since 1988.  As a member of the Atkinson Lions Club he held numerous leadership roles within the club, chaired the Annual Food Drives and the 250th Town of Atkinson Anniversary and was honored as Lion Of The Year in 2018. He was also very involved and sat on the Board of Directors for Glucose Trail.',
	},
];

export default teamData;
