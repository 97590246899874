import React from 'react';
import styled from '@emotion/styled';

import Calculator from '../../public/logos/calculator.svg';
import Movie from '../../public/logos/movie.svg';
import ConceptNote from '../../public/logos/paper.svg';
import Presentation from '../../public/logos/presentation.svg';

import Header from '../components/Header';

const Container = styled.div`
    text-align: center;
    padding: 5vh 5vw 0px 5vw;
    margin: 0 auto;

    @media only screen and (min-width:768px){
        padding: 5vh 20vw 0px 20vw;
    }
`

const ColorLink = styled.div`
    color: #38C938;
`

const ResRow = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 100px);
    column-gap: 45px;
    justify-content: center;
    text-align:center;
`

const RowItem = styled.a`
    cursor: pointer;
    color: #4a4a4a;
    
    :hover{
        color: #38C938;
    }
`

const Icon = styled.img`
    width: 70px;
    height: auto;
    padding: 10px;
`

const Resources = () => {
    return(
        <Container>
            <Header h3>Learn More</Header>
            <ResRow>
                <RowItem href="http://calculator.glucosetrail.com" >
                    <Icon src={Calculator}/>
                    <p>Cost of Care Cost Calculator</p>
                </RowItem>
                <RowItem href="https://youtu.be/YH-yQknqzuo" >
                    <Icon src={Movie}/>
                    <p>Glucose Trail Pilot Video</p>
                </RowItem>
                <RowItem href="https://medium.com/glucose-trail/what-is-glucose-trail-2037c007de9e" >
                    <Icon src={ConceptNote}/>
                    <p>Doctor Naaznin's Concept Note</p>
                </RowItem>
                <RowItem href="https://www.slideshare.net/SaalikLokhandwala/glucose-trail-a-community-based-diabetes-healthcare-solution" >
                    <Icon src={Presentation}/>
                    <p>Glucose Trail Pilot Slideshow</p>
                </RowItem>
            </ResRow>
        </Container>
    )
}

export default Resources;