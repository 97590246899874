import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
//import PostListing from "../components/PostListing/PostListing";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import Layout from '../layouts/index';
//import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


import Home from '../sections/Home';
import Problem from '../sections/Problem';
import Concept from '../sections/Concept';
import Collaborators from '../sections/Collaborators';
import Team from '../sections/Team';
import GetInvolved from '../sections/GetInvolved';
import Resources from '../sections/Resources';
import Footer from '../sections/Footer';

class Index extends React.Component {
  render() {
    //const postEdges = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
      <div className="index-container">
        <Helmet title={config.siteTitle} />
        <SEO />
        <div name="home"><Home/></div>
        <div name="problem"><Problem/></div>
        <div name="concept"><Concept/></div>
        <div name="resources"><Resources /></div>
        <div name="team"><Team /></div>
        <div name="collabs"><Collaborators /></div>
        <div name="getinvolved"><GetInvolved/></div>
        <div name="footer"><Footer /></div>
      </div>
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off"*/
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
