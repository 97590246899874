import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Navbar, NavbarBrand, Collapse, NavbarToggler, Nav } from 'reactstrap';
import Logo from '../../static/logos/glucoseLogo.png';

/**
 * # NavDesktop Component
 */

const Desktop = styled.ul`
	max-width: 100vw;
	margin-top: 12vh;
	margin-left: 5vw;
	padding: 10px;
`;

const NavLogo = styled.img`
  width: auto;
  height: 75px;
  cursor: pointer;
`

/**
 * ## Composition:
 * Parent Desktop styled component that takes up 100vw and 5vh height
 */

class NavDesktop extends Component {
  state = {
    navOpen: false
  }

  toggle = () => {
    this.setState({
      navOpen: !this.state.navOpen,
    })
  }

	render() {
		return (
			<Navbar color="light" light expand="md" fixed={'top'}>
				<NavbarBrand><NavLogo src={Logo}/></NavbarBrand>
				<NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.navOpen} navbar>
          <Nav navbar className="ml-auto">
            {this.props.children}
          </Nav>
        </Collapse>
			</Navbar>
		);
	}
}

export default NavDesktop;
