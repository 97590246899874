import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {css} from '@emotion/core';

/**
 * # Header Component
 */

const head = css`
    text-align: center;
    font-weight: normal;
`

const H1 = styled.h1`
    ${head};
    font-size: 2em;
    margin-bottom: 5px;
    @media only screen and (min-width:768px){
        font-size: 3em;
        letter-spacing: 1.5px;
    }
`

const H2 = styled.h2`
    ${head};
    font-size: 2em;
`

const H3 = styled.h3`
    ${head};
    font-size: 1.2em;
    margin: 15px 0px;
     @media only screen and (min-width:768px){
        font-size: 1.5em;
    }
`

const H4 = styled.h4`
    ${head};
    font-size: 1.2em;
`

const H5 = styled.h5`
    ${head};
    font-size: 1em;
    margin-bottom: 0px;
`

const Label = styled.p`
    ${head};
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: 1px;
`

/**
 * ## Composition:
 * 
 * Checks props of what kind of heading type there is and renders the appropriate sized heading
 */

const Header = (props) => {
    if(props.h1){
        return(<H1>{props.children}</H1>)
    }
    else if(props.h2){
        return(<H2>{props.children}</H2>)        
    }
    else if(props.h3) {
        return(<H3>{props.children}</H3>)        
    }
    else if(props.h4) {
        return(<H4>{props.children}</H4>)
    }
    else if(props.label){
        return(<Label>{props.children}</Label>)
    }
    else{
        return(<H5>{props.children}</H5>)        
    }
}

export default Header

/**
 * ## Props:
 * 
 * - h1: boolean
 * - h2: boolean
 * - h3: boolean
 * - h4: boolean
 * - h5: boolean
 */
Header.propTypes = {
    h1: PropTypes.bool,
    h2: PropTypes.bool,
    h3: PropTypes.bool,
    h4: PropTypes.bool,
    h5: PropTypes.bool,
    label: PropTypes.bool,
}
