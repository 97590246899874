import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {css, keyframes} from '@emotion/core';

/**
 * # Icon Component
 */

const base = css`
    width: 70px;
    height: 70px;
    margin-right: 15px;
    margin-left: 15px;
`

const CircleIcon = styled.img`
    ${base};
    border-radius: 50%;
`

const SquareIcon = styled.img`
    ${base};
`

const Rounded = styled.img`
    ${base};
    border-radius: 5px;
`

const Bounce = keyframes`
    0%, 100% {
        transform: translate(0px, -5px);
    }

    50% {
        transform: translate(0px, 5px);
    }
`

const Bouncing = styled.img`
    ${base};
    transition: all 0.3s ease 1s;
    animation: ${Bounce} 2s ease-in-out infinite;
    animation-delay: 1s;
`

const Wave = keyframes`
    0%, 100%{
        transform: rotate(-18deg);
    }

    50%{
        transform: rotate(18deg);
    }
`

const Waving = styled.img`
    ${base};
    transition: all 0.3s ease 1s;
    animation: ${Wave} 2s ease-in-out infinite;
    animation-delay: 1s;
`

const Flash = keyframes`
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }
`

const Flashing = styled.img`
    ${base};
    padding-top: 80px;
    width: 130px;
    transition: all 0.1s ease 1s;
    animation: ${Flash} 3s ease-in-out infinite;
    animation-delay: 1s;
`

/**
 * ## Composition:
 * 
 * Checks what kind of icon is desired based on props and then renders the icon in that form. Pass the image in using the src prop.
 * The default type is square.
 * 
 * All icons are 50px by 50px.
 * 
 * Types: Circle, Rounded Corners, Square
 */

const Icon = (props) => {
    if(props.circle){
        return(<CircleIcon src={props.src}/>)
    }
    else if(props.rounded){
        return(<Rounded src={props.src}/>)
    }
    else if(props.bouncing) {
        return(<Bouncing src={props.src}/>)
    }
    else if(props.waving){
        return(<Waving src={props.src}/>)
    }
    else{
        return(<SquareIcon src={props.src}/>)        
    }
}

export default Icon

/**
 * ## Props:
 * 
 * - circle: boolean
 * - square: boolean
 * - rounded: boolean
 * - src: string
 */
Icon.propTypes = {
    circle: PropTypes.bool,
    square: PropTypes.bool,
    rounded: PropTypes.bool,
    bouncing: PropTypes.bool,
    waving: PropTypes.bool,
    flashing: PropTypes.bool,
    src: PropTypes.string
}
