import React from 'react';
import styled from '@emotion/styled';

import Image from '../components/Image';
import logo from '../../static/logos/IconLogo.svg';

const Container = styled.div`
    margin-top: 20vh;
    text-align: center;
    padding-bottom: 10vh;
`

const Copyright = styled.p`
    margin-top: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 0.7em;
    color: #a4a4a4;
`

const EmailLink = styled.a`
	color: #38c938;
`;

const Footer = (props) => {
    return(
        <Container>
            <Image small src={logo}/>
            <EmailLink href="mailto:info@glucosetrail.org?subject=Let's Get In Touch">info@glucosetrail.com</EmailLink>
            <Copyright>&copy; 2019 Glucose Trail Inc. All Rights Reserved.</Copyright>
        </Container>
    )
}

export default Footer;