import React from 'react';
import styled from '@emotion/styled';

import Header from '../components/Header';
import Image from '../components/Image';

import world from '../../static/logos/world.svg';
import pulse from '../../static/logos/s-pulse.svg';
import chart from '../../static/logos/chart-growth.svg';

import CostGraph from '../components/CostGraph';


const Container = styled.div`
    padding-top: 10vh;
    padding-bottom: 10vh;

    @media only screen and (min-width:768px){
        padding-top: 23vh;
        padding-bottom: 23vh;   
    }
`

const StatRow = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
`

const Stat = styled.div`
    width: 90vw;
    
    @media only screen and (min-width:768px){
        max-width: 20vw;    
    }

    text-align: center;
`

const SubLine = styled.p`
    text-align: center;
    margin-top: 10px;
`

const GraphContainer = styled.div`
    width: 100%;

    @media only screen and (min-width:768px){
        max-width: 50%;    
    }
    margin: 0 auto;
    display: block;
`

const Problem = (props) => {
    return(
        <Container>
            <Header h2>Diabetes: a BIG Problem</Header>
            
            <StatRow>
                <Stat>
                    <Image square src={world}/>
                    <Header h1>228 Million</Header>
                    <p>have diabetes worldwide</p>
                </Stat>
                <Stat>
                    <Image square src={chart}/>
                    <Header h1>+170%</Header>
                    <p>in developing countries by 2025</p>
                </Stat>
                <Stat>
                    <Image square src={pulse}/>
                    <Header h1>48%</Header>
                    <p>of diabetes related deaths are people under 60 years old in developing countries</p>
                </Stat>
            </StatRow>
            <SubLine>Treating diabetes early is easier, significantly less expensive and ensures quality of life.</SubLine>
            <GraphContainer>
                <CostGraph/>
            </GraphContainer>
        </Container>
    )
}

export default Problem;